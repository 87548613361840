/**
 * This file contains locale strings which were extracted from the SDK `locale` module
 * (e.g., because they had customer-specific terms in them), as well as any future
 * locale strings needed only at the B2B level.
 */

export const strings = {
  default: {
    DOMAINS: 'Domains',
    DOMAINS_COUNT: '{count} domains',
    PC_HEALTH_LOGO: 'PC Health logo',
    PC_HEALTH_ID: 'PC Health ID',
    MAPLE_USER_TYPE: 'Maple User Type',
    MAPLE_CONSENT_DATE: 'Maple Consent Date',
    MAPLE_UNLINK_DATE: 'Maple Unlink Date',
    NO_MAPLE_ACCOUNT_LINKED: 'No Maple account linked',
    SDM_MAPLE_USER:
      'SDM user - this user was first introduced to Maple through PC Health',
    SHARED_MAPLE_USER:
      'Shared user - this user linked their existing Maple account to PC Health',
    CURRENT_MAPLE_USER_STATUS: 'Current Maple User Status',
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Should the member wish to access Maple through the PC Health handoff flow, please share the following options:',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      '(Existing Maple User) Use existing Maple account credentials within the PC Health handoff flow to access Maple, and link their PC Health account.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(New Maple User) Create a new Maple account through the PC Health handoff flow to link their PC Health account.',
    MAPLE_USER_PROFILE: 'Maple User Profile',
  },
  es: {
    PC_HEALTH_ID: 'ID de PC Health',
    PC_HEALTH_LOGO: 'Logo de PC Salud',
    MAPLE_USER_PROFILE: 'Perfil del usuario de Maple',
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(Usuario nuevo de Maple) Crea una cuenta nueva de Maple a través del flujo de transferencia de PC Health para vincular tu cuenta de PC Health.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      '(Usuario actual de Maple) Utiliza las credenciales de tu cuenta existente de Maple dentro del flujo de transferencia de PC Health para acceder a Maple y vincular tu cuenta de PC Health.',
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Si el miembro desea acceder a Maple a través del flujo de transferencia de PC Health, comparte las siguientes opciones:',
    CURRENT_MAPLE_USER_STATUS: 'Estatus actual de usuario de Maple',
    SHARED_MAPLE_USER:
      'Usuario compartido: este usuario vinculó su cuenta existente de Maple a PC Health',
    SDM_MAPLE_USER:
      'Usuario de SDM: este usuario se introdujo por primera vez a Maple a través de PC Health',
    NO_MAPLE_ACCOUNT_LINKED: 'No hay ninguna cuenta de Maple vinculada',
    MAPLE_UNLINK_DATE: 'Fecha de desvinculación de Maple',
    MAPLE_CONSENT_DATE: 'Fecha de consentimiento a Maple',
    MAPLE_USER_TYPE: 'Tipo de usuario de Maple',
  },
  fr: {
    PC_HEALTH_ID: 'ID PC Santé',
    PC_HEALTH_LOGO: 'Logo PC Santé',
    MAPLE_USER_PROFILE: "Profil de l'utilisateur Maple",
    UNLINKED_MAPLE_ACCOUNT_OPTION_TWO:
      '(Nouvel utilisateur Maple) Créez un nouveau compte Maple via le flux de transfert PC Santé pour lier leur compte PC Santé.',
    UNLINKED_MAPLE_ACCOUNT_OPTION_ONE:
      "(Utilisateur Maple préexistant) Utilisez les informations d'identification de compte Maple préexistantes dans le flux de transfert PC Santé pour accéder à Maple et liez leur compte PC Santé.",
    UNLINKED_MAPLE_ACCOUNT_OPTIONS:
      'Si le membre souhaite accéder à Maple via le flux de transfert PC Santé, veuillez partager les options suivantes:',
    CURRENT_MAPLE_USER_STATUS: "Statut actuel d'utilisateur Maple",
    SHARED_MAPLE_USER:
      'Utilisateur partagé - cet utilisateur a associé son compte Maple préexistant à PC Santé',
    SDM_MAPLE_USER:
      'Utilisateur Pharmaprix - cet utilisateur a été introduit pour la première fois à Maple via PC Santé',
    NO_MAPLE_ACCOUNT_LINKED: 'Aucun compte Maple associé',
    MAPLE_UNLINK_DATE: 'Date de dissociation Maple',
    MAPLE_CONSENT_DATE: 'Date de consentement Maple',
    MAPLE_USER_TYPE: "Type d'utilisateur Maple",
  },
};
